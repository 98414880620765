<template>
  <div>
    <el-row :gutter="32">
      <el-col :xs="24" :sm="24" :lg="12">
        <factory-tabs @selectfactoryId='selectfactoryId' />
        <div class="info-wrapper" style="margin-top:30px">
          <div>
            <template v-for="(item,index) in list0">
              <span :key="index" :style="{ width:(100/list0.length)+'%' }">{{item}}</span>
            </template>
          </div>
          <div v-if="factoryInfo">
            <template v-for="(item,index) in factoryInfo.product">
              <span :key="index" :style="{ width:(100/list0.length)+'%' }">{{item}}</span>
            </template>
          </div>
        </div>
        <div class="info-wrapper">
          <div>
            <template v-for="(item,index) in list1">
              <span :key="index" :style="{ width:(100/list1.length)+'%' }">{{item}}</span>
            </template>
          </div>
          <div v-if="factoryInfo">
            <template v-for="(item,index) in factoryInfo.employee">
              <span :key="index" :style="{ width:(100/list1.length)+'%' }">{{item}}</span>
            </template>
          </div>
        </div>
        <div class="info-wrapper">
          <div>
            <template v-for="(item,index) in list2">
              <span :key="index" :style="{ width:(100/list2.length)+'%' }">{{item}}</span>
            </template>
          </div>
          <div v-if="factoryInfo">
            <template v-for="(item,index) in factoryInfo.factoryDevice">
              <span :key="index" :style="{ width:(100/list2.length)+'%' }">{{item}}</span>
            </template>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :lg="12" style="position: relative;">
        <div style="width:150px;height:30px;display: flex;align-items: center;justify-content: space-between;flex-direction: row;">
          <div>
            <el-dropdown @command="selectproductAttrId">
              <div class="dropdown" style="font-size:16px;">
                {{productAttrId}}<i class="el-icon-caret-bottom" style="float:right"></i>
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :command="item.attrName" v-for="item in categoryList" :key="item.id">{{item.attrName}}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div>
            <img src="../../../../assets/switch.png" class="switch_img" @click="changetype">
          </div>
        </div>
        <div class="chart-wrapper" v-if="type=='2'">
          <pie-chart :chartData="factoryInfo.productCategory" v-if="factoryInfo && factoryInfo.productCategory.length>0" :chartIsLoad="chartIsLoad" />
          <div class="no-more" v-else>
            <img src="../../../../assets/no-more.png" />
            <span>暂无数据</span>
          </div>
        </div>
        <!-- 表格数据 -->
        <div class="chart-wrapper" v-if="type=='1'">
          <div v-if="factoryInfo&&factoryInfo.productCategory.length>0">
            <template>
              <el-table
                :data="factoryInfo.productCategory"
                stripe
                height="380"
                style="width: 100%;margin-top:30px;"
                :header-cell-style="{background:'#f7f8fa'}">
                <el-table-column
                  prop="categoryName"
                  label="类别名称"
                  align="center">
                </el-table-column>
                <el-table-column
                  prop="count"
                  label="数量"
                  align="center">
                </el-table-column>
                <el-table-column
                  prop="proportion"
                  label="占比"
                  align="center">
                </el-table-column>
              </el-table>
            </template>
          </div>
          <div class="no-more" v-else>
            <img src="../../../../assets/no-more.png" />
            <span>暂无数据</span>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { selectFactoryModular } from '@/api'
import {selectProductAttrList}from '@/api/modules/productattr'
export default {

  components: {
    FactoryTabs: () => import('./FactoryTabs'),
    PieChart: () => import('./PieChart')
  },
  data() {
    return {
      list0: ["产品总数", "生产产品数", "实验产品数", "过程产品数"],
      list1: ["工厂管理员", "实验室主管", "打样员", "录入人员", "查色人员"],
      list2: ["工厂设备", "授权设备", "授权人数", "授权过期"],
      factoryId: null,
      factoryInfo: null,
      chartIsLoad: false,
      type:'1',
      categoryList:[],
      productAttrId:'',
    }
  },
  created(){
    this.selectProductAttrList()
  },
  mounted() {
    // this.selectFactoryModular()
  },
  methods: {
    async selectFactoryModular() {
      const params = {
        factoryId: this.factoryId,
        productAttrId:this.categoryList.find(item =>{
          if (item.attrName == this.productAttrId) {
            return item
          }
        }).productAttrId
      }
      const { data } = await selectFactoryModular(params)
      if (data && data.code === 0) {
        this.factoryInfo = data.data
        delete this.factoryInfo.employee.brandManager
      }
    },
    selectProductAttrList(){
      var params = {
        isUniversal:'0'
      }
      selectProductAttrList(params).then(({data}) => {
        if (data && data.code === 0) {
          this.categoryList = data.productAttrList
          this.productAttrId = data.productAttrList[0].attrName
        } else {
          this.$message.error(data.msg);
        }
      })
    },
    selectfactoryId(e){
      this.factoryId = e
      this.factoryInfo = null
      setTimeout(() => {
        this.selectFactoryModular()
      }, 1000);
    },
    selectproductAttrId(id){
      this.productAttrId = id
      this.factoryInfo = null
      this.selectFactoryModular()
    },
    changetype(){
      if (this.type == '1') {
        this.type = '2'
      }else{
        this.type = '1'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.info-wrapper {
  background: #f7f8fa;
  height: 104px;
  margin-bottom: 30px;
  div {
    display: flex;
    text-align: center;
    padding-top: 22px;
    &:nth-child(1) {
      span {
        color: #666;
        font-size: 14px;
      }
    }
    &:nth-child(2) {
      span {
        color: #000;
        font-size: 24px;
      }
    }
  }
}
.switch_img{
  width: 20px;
  height: 20px;
}
.chart-wrapper {
  .no-more {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 100px;
    span{
      font-size: 14px;
      color: #000;
      padding-top: 10px;
    }
  }
}
</style>
<style rel="stylesheet/scss" lang="scss" scoped>
.chart-wrapper {
  .el-table th.is-leaf{
    border: none;
  }
  .el-table::before {
    height: 0px;
  }
}
</style>